import { CardHeader, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import RecommendationsDialog from "./Recommendations";
import { TableReco } from "./TableReco";


const RecommendationsSection = ({
  openReco,
  setOpenReco,
  basicInfo,
  cvId,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading?: any;
  loading?: any;
  fetchGetOneReco?: any;
  basicInfo?: any;
  cvId?: any;
  openReco?: any;
  setOpenReco?: (openReco: boolean) => void;
}) => {
 
  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          {basicInfo?.resume_recommendations?.length==0?<Button
          variant="contained"
       
          color="primary"
          startIcon={<AddIcon />} 
          onClick={() => setOpenReco(true)}
        >
          Add Refrence
        </Button>:<Button
          variant="contained"
          className="add-button-resume-section"
          color="primary"
          startIcon={<AddIcon />} 
          onClick={() => setOpenReco(true)}
        >
          Add Refrence
        </Button>}
        
          <CardHeader
            title="Refrences"
            className="tabs-card-resumes"
            // Add cursor pointer for indication
          />
          <Stack style={{ paddingLeft: "10px" }}>
            <TableReco
              cvId={cvId}
              recoData={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneReco={fetchGetOneReco}
            />
          </Stack>
        </Stack>
      </Stack>
      
       
        {openReco && (
          <RecommendationsDialog
            openReco={openReco}
            setOpenReco={setOpenReco}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneReco={fetchGetOneReco}
          />
        )}
     
    </>
  );
};

export default RecommendationsSection;
