import { CardHeader, Button, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EducationDialog from "./Education";
import { TableEducation } from "./TableEducation";

import { useState } from "react";


import { EducationSectionModel } from "model/cv/education/education";


const EducationSection = ({
  open,
  setOpen,
  cvId,
  basicInfo,
  setLoading,
  loading,
  fetchGetOneEdu,
}: {
  fetchGetOneEdu: any;
  loading: any;
  setLoading: any;
  basicInfo: any;
  open: any;
  setOpen: (open: boolean) => void;
  cvId: any;
}) => {
  // @ts-ignore
  const [rows, setRows] = useState<EducationSectionModel[]>([
    {
      resume_id: cvId,
      educational_institution_id: 0,
      degree_type_id: 0, // Update to match input component naming convention
      specialization: "",
      average: 0,
      country: "",
      city: "",
      graduation_year: "", // Assuming it can be null and it's a string (URL?)
    },
  ]);

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          {basicInfo.resume_educations.length==0?<Button
          variant="contained"
      
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          Add Education
        </Button>:<Button
          variant="contained"
          className="add-button-resume-section"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          Add Education
        </Button>}
       
          <CardHeader className="tabs-card-resumes" title="Education" ></CardHeader>

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableEducation
              cvId={cvId}
              educationCertificates={basicInfo}
              fetchGetOneEdu={fetchGetOneEdu}
              loading={loading}
              setLoading={setLoading}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        
        {open && (
          <EducationDialog
            open={open}
            setOpen={setOpen}
            cvId={cvId}
            fetchGetOneEdu={fetchGetOneEdu}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </>
  );
};

export default EducationSection;
