import { CardHeader, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableCourses } from "./TableCourse";

import {  useState } from "react";


import CoursesDialog from "./Courses";
import { Course } from "model/cv/courses/course";


const CouurseSection = ({
  openCourses,
  setOpenCourses,
  cvId,
  basicInfo,
  setLoading,
  loading,
  fetchGetOneCourse,
}: {
  fetchGetOneCourse: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openCourses: any;
  setOpenCourses: (open: boolean) => void;
  cvId: any;
}) => {
    // @ts-ignore
  const [rows, setRows] = useState<Course[]>([
    {
      resume_id: 0,
      additional_info: "",
      educational_institution_id: 0,
      course_name: "",
      country: "",
      city: "",
      certificate_date: "",
      course_field: "",
      course_descriptions: "",
      certificate: false,
      // Assuming it can be null and it's a string (URL?)
    },
  ]);


  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          {basicInfo?.resume_courses?.length==0?<Button
          variant="contained"
       
          color="primary"
          startIcon={<AddIcon />} 
          onClick={() => setOpenCourses(true)}
        >
          Add Course
        </Button>:<Button
          variant="contained"
          className="add-button-resume-section"
          color="primary"
          startIcon={<AddIcon />} 
          onClick={() => setOpenCourses(true)}
        >
          Add Course
        </Button>}
        
          <CardHeader title="Attended Coursers" className="tabs-card-resumes" />
          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableCourses
              cvId={cvId}
              coursCertificates={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneCourse={fetchGetOneCourse}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: rows.length > 0 ? "5%" : "0",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
      
        {openCourses && (
          <CoursesDialog
            openCourses={openCourses}
            setOpenCourses={setOpenCourses}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneCourse={fetchGetOneCourse}
          />
        )}
      </div>
    </>
  );
};

export default CouurseSection;
