import { CardHeader, Button, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableSkill } from "./TableSkill";
import SkillsDialog from "./Skill";


const SkillSection = ({
  cvId,
  basicInfo,
  openSkills,
  setOpenSkills,
  setLoading,
  loading,
  fetchGetOneSkill,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneSkill: any;
  cvId: any;
  basicInfo: any;

  openSkills: any;
  setOpenSkills: (open: boolean) => void;
}) => {
 

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          {basicInfo?.resume_skills?.length==0? <Button
          variant="contained"
     
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenSkills(true)}
        >
          Add Skill
        </Button>: <Button
          variant="contained"
          className="add-button-resume-section"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenSkills(true)}
        >
          Add Skill
        </Button>}
        
          <CardHeader className="tabs-card-resumes" title="Skills" />

          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableSkill
              cvId={cvId}
              skillData={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneSkill={fetchGetOneSkill}
            />
          </Stack>
        </Stack>

        {/* Other education-related fields */}
      </Stack>
     
        {openSkills && (
          <SkillsDialog
            openSkills={openSkills}
            setOpenSkills={setOpenSkills}
            cvId={cvId}
            setLoading={setLoading}
            loading={loading}
            fetchGetOneSkill={fetchGetOneSkill}
          />
        )}
     
    </>
  );
};

export default SkillSection;
