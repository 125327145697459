  import { useEffect, useState, ChangeEvent } from 'react';
  import { Link } from 'react-router-dom';

  // material-ui
  import { useTheme } from '@mui/material/styles';
  import { Box, FormLabel, Grid, TextField, Menu, MenuItem, Stack, Typography } from '@mui/material';

  // project import
  import MainCard from 'components/MainCard';




  // assets
  import { MoreOutlined, CameraOutlined } from '@ant-design/icons';
  import IconButton from 'components/@extended/IconButton';

  // types
  import { ThemeMode } from 'types/config';




  // ==============================|| USER PROFILE - TAB CONTENT ||============================== //


  const ProfileTabs = ({data}) => {
    const theme = useTheme();
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    //const [avatar, setAvatar] = useState<string | undefined>(avatarImage(''));

    useEffect(() => {
      if (selectedImage) {
      //  setAvatar(URL.createObjectURL(selectedImage));
      }
    }, [selectedImage]);

    const [anchorEl, setAnchorEl] = useState<Element | (() => Element) | null | undefined>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
      setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
      <MainCard >
        <Grid container spacing={6} >
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <IconButton
                variant="light"
                color="secondary"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreOutlined />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem
                  component={Link}
                  to="/apps/profiles/user/personal"
                  onClick={() => {
                    handleClose();
                    
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem onClick={handleClose} disabled>
                  Delete
                </MenuItem>
              </Menu>
            </Stack>
            <Stack spacing={2.5} alignItems="center">
              <FormLabel
                htmlFor="change-avtar"
                sx={{
                  position: 'relative',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  '&:hover .MuiBox-root': { opacity: 1 },
                  cursor: 'pointer'
                }}
              >
               
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Stack spacing={0.5} alignItems="center">
                    <CameraOutlined style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                    <Typography sx={{ color: 'secondary.lighter' }}>Upload</Typography>
                  </Stack>
                </Box>
              </FormLabel>
              <TextField
                type="file"
                id="change-avtar"
                placeholder="Outlined"
                variant="outlined"
              style={{display:"none"}}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedImage(e.target.files?.[0])}
              />
              <div className='user-profile-placeholder'>
              <img alt='userprofile' src={data?.user?.profile_image} />
              </div>
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">{data?.user?.user_name}</Typography>
                <Typography color="secondary">{data?.user?.email}</Typography>
              </Stack>
             
            </Stack>
            <Stack style={{marginTop:"10px"}}  direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Grid item xs={12} sm={10}>
            <Typography >Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography  style={{marginTop:"0px"}} color="secondary">{data?.user?.account_status}</Typography>
        </Grid>
              </Stack>
            <Stack style={{marginTop:"10px"}}  direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Grid item xs={12} sm={10}>
            <Typography >Registration Date</Typography></Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.user?.registeration_date}</Typography>
          </Grid>
              </Stack>
              
            
          </Grid>
          <Grid item sm={3} sx={{ display: { sm: 'block', md: 'none' } }} />
         
          {/* <Grid item xs={12}>
          <ProfileTab/>
          </Grid> */}
        </Grid>
      </MainCard>
      <MainCard style={{marginTop:"10px"}} title="Applications" >
        <Grid container spacing={6} >
          <Grid item xs={12}>
            
            <Stack direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Grid item xs={12} sm={10}>
            <Typography >Application Count</Typography></Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.applications?.total_applications}</Typography>
            </Grid>
              </Stack>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Read Application Count</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">500</Typography>
            </Grid>
              </Stack>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Last Applied</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.applications?.last_application_date}</Typography>
            </Grid>
              </Stack>
            
          </Grid>
          </Grid>
         
      </MainCard>
      <MainCard style={{marginTop:"10px"}} title="Arabic Resume" >
        <Grid container spacing={6} >
          <Grid item xs={12}>
            
            <Stack direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Grid item xs={12} sm={10}>
            <Typography >Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.arabic_resume?.status===false ?"Disactive":"Active"}</Typography>
           </Grid>
              </Stack>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Last Update on</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.arabic_resume?.last_update}</Typography>
                 </Grid>
                    </Stack>
              <Stack style={{marginTop:"14px"}}  direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Highest Education Level</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.arabic_resume?.highest_education}</Typography>
            </Grid>
              </Stack>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Reference Count</Typography></Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.arabic_resume?.reference_count}</Typography>
           </Grid>
              </Stack>
              <Typography style={{marginTop:"12px",  borderBottom: "solid 1.25px #a5a0a0", paddingBottom: "8px"}} variant="h5" >Work Eexperience</Typography>
             <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
             <Grid item xs={12} sm={7}><Stack style={{marginTop:"14px"}} direction="column" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Typography >Total Years of Experience</Typography>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.arabic_resume?.total_years_of_experience}</Typography>
              </Stack></Grid>
             <Grid item xs={12} sm={5}>
             <Stack style={{marginTop:"14px"}} direction="column" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Typography >Field of Work</Typography>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.arabic_resume?.field_of_work.name1}</Typography>
              </Stack>
             </Grid>
             </Stack>
              
              
              
          </Grid>
          
          </Grid>
         
      </MainCard>

      <MainCard style={{marginTop:"10px"}} title="English Resume" >
        <Grid container spacing={6} >
          <Grid item xs={12}>
            
            <Stack direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
            <Grid item xs={12} sm={10}>
            <Typography >Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.english_resume?.status===false ?"Disactive":"Active"}</Typography>
          </Grid>
              </Stack>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Last Update on</Typography></Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.english_resume?.last_update}</Typography>
                  </Grid>
                    </Stack>
              <Stack style={{marginTop:"14px"}}  direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Highest Education Level</Typography></Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.english_resume?.highest_education}</Typography>
       </Grid>
              </Stack>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={10}>
            <Typography >Reference Count</Typography></Grid>
            <Grid item xs={12} sm={2}>
            <Typography style={{marginTop:"0px"}} color="secondary">{data?.english_resume?.reference_count}</Typography>
        </Grid>
              </Stack>
              

              <Typography style={{marginTop:"12px",  borderBottom: "solid 1.25px #a5a0a0", paddingBottom: "8px"}} variant="h5" >Work Eexperience</Typography>
              <Stack style={{marginTop:"14px"}} direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
              <Grid item xs={12} sm={7}>
              <Typography >Total Years of Experience</Typography>
              <Typography style={{marginTop:"0px"}} color="secondary">{data?.english_resume?.total_years_of_experience}</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
              <Typography >Field of Work</Typography>
              <Typography style={{marginTop:"0px"}} color="secondary">{data?.english_resume?.field_of_work.name1}</Typography>
              </Grid>
              
              </Stack>
             
            
              
          </Grid>
          
          </Grid>
         
      </MainCard>
      </>
    );
  };

  export default ProfileTabs;
