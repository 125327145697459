import React, { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT, SET_TOKEN } from "contexts/auth-reducer/actions";
import authReducer from "contexts/auth-reducer/auth";

// project import
import Loader from "components/Loader";
import axios from "utils/axios";
import { KeyedObject } from "types/root";
import { AuthProps, JWTContextType } from "types/auth";


const chance = new Chance();

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  token: null,
};

const verifyToken: (st: string) => boolean = (token) => {
  if (!token) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(token);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

export const setSession = (token?: string | null) => {
  if (token) {
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const token = window.sessionStorage.getItem("token");
        if (token && verifyToken(token)) {
          setSession(token);
          const response = await axios.get(
            "https://test.syriajob.com/public/api/get_user",
          );
          const user = response.data;
          console.log(user);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
          dispatch({ type: SET_TOKEN });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(
      "https://test.syriajob.com/public/api/user_register/login",
      { email, password },
    );
    const user = response.data;

    setSession(user.token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
      },
    });
    window.location.reload();
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      window.sessionStorage.getItem("users") !== undefined &&
      window.sessionStorage.getItem("users") !== null
    ) {
      const localUsers = window.sessionStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.sessionStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
