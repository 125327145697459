import { Fragment, MouseEvent, useEffect, useMemo, useState } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _debounce from "lodash/debounce";
// third-party
import { PatternFormat } from "react-number-format";
import {
  ColumnDef,
  HeaderGroup,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
  SortingState,
  FilterFn,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

// project-import
import ScrollX from "components/ScrollX";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";

import {
  HeaderSort,
  IndeterminateCheckbox,
  RowSelection,
  SelectColumnSorting,
  TablePagination,
} from "components/@extended/react-table";

// types

import { LabelKeyObject } from "react-csv/lib/core";

// assets
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useAppDispatch } from "hooks";
import ExpandingVacancyDetail from "./ExpandingVacancyDetails";
import AlertVacancyDelete from "./AlertVacancyDelete";
import VacancyModal from "./VacancyModal";
import { VacancyOrder } from "model/order";
import {
  FilterVacancyOrder,
  MultiDeletVacancyOrder,
  getAllVacanciesOrder,
} from "store/actions/main/order/vacancyOrder";
import Loader from "components/Loader";
import { DatePicker, Select } from "antd";
import { Search } from "@mui/icons-material";

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // store the ranking info
  addMeta(itemRank);

  // return if the item should be filtered in/out
  return itemRank.passed;
};

// ==============================|| REACT TABLE - LIST ||============================== //

function ReactTable({
  countPage,
  setPageSize,
  pageSize,
  currentPage,
  handlePageChange,
  data,
  columns,
  showinBestPlace,
  showInApp,
  setshowInApp,
  fetchData,
  setshowinBestPlace,
  setLogo,
  logo,
  setStatus,
  status,
  handleDateChange,
  handleApplyFilters,
  setCurrenttPage,
  modalToggler,
}: any) {
  const theme = useTheme();
  const { RangePicker } = DatePicker;
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: false,
    },
  ]);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,

    columns,
    state: {
      sorting,
      rowSelection,
      globalFilter,
    },

    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    globalFilterFn: fuzzyFilter,
    debugTable: true,
  });

  const backColor = alpha(theme.palette.primary.lighter, 0.1);
  let headers: LabelKeyObject[] = [];
  columns.map(
    (columns: any) =>
      // @ts-ignore
      columns.accessorKey &&
      headers.push({
        label: typeof columns.header === "string" ? columns.header : "#",
        // @ts-ignore
        key: columns.accessorKey,
      }),
  );
  const dispatch = useAppDispatch();
  const selectedRowIds = useMemo(() => {
    // Get the selected rows from the table object
    const selectedRows = table
      .getSortedRowModel()
      .flatRows.filter((row) => row.getIsSelected());

    // Map over the selected rows to extract their IDs
    return selectedRows.map((row) => row.original.id);
  }, [table, rowSelection]);
  const handleMultiDelet = () => {
    dispatch(MultiDeletVacancyOrder({ ids: selectedRowIds }));
    fetchData();
  };
  return (
    <MainCard content={false}>
      <Grid direction={"column"}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: 2,
            ...(matchDownSM && {
              "& .MuiOutlinedInput-root, & .MuiFormControl-root": {
                width: "100%",
              },
            }),
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            <SelectColumnSorting
              {...{
                getState: table.getState,
                getAllColumns: table.getAllColumns,
                setSorting,
              }}
            />
          </Stack>
          <Stack display={"flex"} justifyContent={"space-between"}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteOutlined />}
                onClick={handleMultiDelet}
                disabled={Object.keys(rowSelection).length === 0}
              >
                Delete Multi Items
              </Button>
              <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={modalToggler}
              >
                Add new
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Grid container mt={1} justifyContent={'center'}>
            <Typography style={{textAlign:'center', fontSize:'19px', fontWeight:'bold', color:'#1677ff', margin:'5px'}}>Advanced filter</Typography>
            <Search/>
        </Grid>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: 2,
            ...(matchDownSM && {
              "& .MuiOutlinedInput-root, & .MuiFormControl-root": {
                width: "100%",
              },
            }),
          }}
        >
          <Grid container>
            <Grid md={3} mb={1}>
            <InputLabel id="account_display_logo">Show Logo</InputLabel>
              <FormControl fullWidth>
                <Select
                  id="customer-display_logo"
                  value={logo}
                  style={{ height: "40px" }}
                  onChange={(event: SelectChangeEvent<string>) => {
                    let selectedValue;
                    if (typeof event === "boolean") {
                      selectedValue = event;
                    } else {
                      selectedValue = event;
                    }
                    setLogo(selectedValue);
                  }}
                  // rest of the code
                >
                  <MenuItem value={null}>All</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1}/>
            <Grid md={3} mb={1}>
            <InputLabel id="account_display_in_app">Show In App</InputLabel>
              <FormControl fullWidth>
                <Select
                  id="customer-display_in_app"
                  value={showInApp}
                  style={{ height: "40px" }}
                  onChange={(event: SelectChangeEvent<string>) => {
                    console.log("Event:", event);
                    let selectedValue;
                    if (typeof event === "boolean") {
                      selectedValue = event;
                    } else {
                      selectedValue = event;
                    }
                    setshowInApp(selectedValue);
                  }}
                  // rest of the code
                >
                  <MenuItem value={null}>All</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1}/>
            <Grid md={3} mb={1}>
            <InputLabel id="account_display_in_best_place">
                Show In Best Place
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  id="customer-display_in_best_place"
                  value={showinBestPlace}
                  style={{ height: "40px" }}
                  onChange={(event: SelectChangeEvent<string>) => {
                    let selectedValue;
                    if (typeof event === "boolean") {
                      selectedValue = event;
                    } else {
                      selectedValue = event;
                    }
                    setshowinBestPlace(selectedValue);
                  }}
                  // rest of the code
                >
                  <MenuItem value={null}>All</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid md={2}/>
            <Grid md={3} mb={1}>
            <InputLabel id="account_status">Status</InputLabel>
              <FormControl fullWidth>
                <Select
                  id="customer-status"
                  value={status}
                  style={{ height: "40px" }}
                  onChange={(event: SelectChangeEvent<string>) => {
                    let selectedValue;
                    if (typeof event === "boolean") {
                      selectedValue = event;
                    } else {
                      selectedValue = event;
                    }
                    setStatus(selectedValue);
                  }}
                >
                  <MenuItem value={null}>All</MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1}/>
            <Grid md={3} mb={1}>
              
            <InputLabel id="account_display_in_best_place">
                Between date:
              </InputLabel>
            <RangePicker
                onChange={handleDateChange}
                style={{ height: "40px" }}
              />
            </Grid>
            <Grid md={1}/>
            <Grid md={1}>
              <Tooltip title={'Apply filter'}>
              <Button style={{
                      marginTop: '20px',
                      width: '35px',
                      height: '35px',
                      borderRadius: '50%',
                      minWidth: '0',
                      padding: '0',
                    }}
                    variant="outlined"
                    onClick={handleApplyFilters}>
                  <Search style={{color:'#1677ff'}}/>
              </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <ScrollX>
        <Stack>
          <RowSelection selected={Object.keys(rowSelection).length} />
          <TableContainer>
            <Table>
              <TableHead>
                {table
                  .getHeaderGroups()
                  .map((headerGroup: HeaderGroup<any>) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (
                          header.column.columnDef.meta !== undefined &&
                          header.column.getCanSort()
                        ) {
                          Object.assign(header.column.columnDef.meta, {
                            className:
                              header.column.columnDef.meta.className +
                              " cursor-pointer prevent-select",
                          });
                        }

                        return (
                          <TableCell
                            key={header.id}
                            {...header.column.columnDef.meta}
                            onClick={header.column.getToggleSortingHandler()}
                            {...(header.column.getCanSort() &&
                              header.column.columnDef.meta === undefined && {
                                className: "cursor-pointer prevent-select",
                              })}
                          >
                            {header.isPlaceholder ? null : (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Box>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </Box>
                                {header.column.getCanSort() && (
                                  <HeaderSort column={header.column} />
                                )}
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          {...cell.column.columnDef.meta}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() && (
                      <TableRow
                        sx={{
                          bgcolor: backColor,
                          "&:hover": { bgcolor: `${backColor} !important` },
                        }}
                      >
                        <TableCell colSpan={row.getVisibleCells().length}>
                          <ExpandingVacancyDetail data={row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <TablePagination
                {...{
                  setPageSize: setPageSize,
                  setPageIndex: setCurrenttPage,
                  getState: table.getState,
                  getPageCount: () => countPage.last_page,
                  currentPage: currentPage,
                  handlePageChange: handlePageChange,
                  initialPageSize: pageSize,
                }}
              />
            </Box>
          </>
        </Stack>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| CUSTOMER LIST ||============================== //

const VacanciesOrder = () => {
  const theme = useTheme();

  //const { customersLoading, customers: lists } = useGetCustomer();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<VacancyOrder | null>(
    null,
  );
  const [customerDeleteId, setCustomerDeleteId] = useState<any>("");

  const handleClose = () => {
    setOpen(!open);
  };

  const columns = useMemo<ColumnDef<VacancyOrder>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        header: "#",
        accessorKey: "id",
        meta: {
          className: "cell-center",
        },
      },

      {
        header: "Company Name",
        accessorKey: "company_name",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Count Vacancies",
        accessorKey: "amount_vacancies",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
            </Stack>
          </Stack>
        ),
      },

      {
        header: "Fees",
        accessorKey: "price",
        cell: ({ row, getValue }) => {
          const price = parseFloat((getValue() as string) || "0"); // Explicitly specify the type as string
          const formattedPrice = price.toFixed(2).replace(/\.?0+$/, "");
          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography variant="subtitle1">{formattedPrice}</Typography>
                <Typography color="text.secondary"></Typography>
              </Stack>
            </Stack>
          );
        },
      },

      {
        header: "Validatity",
        accessorKey: "package_validity",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Duration",
        accessorKey: "package_duration",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Payment",
        accessorKey: "payment_id",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Urgent",
        accessorKey: "urgent",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Urgent",
        accessorKey: "urgent",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Best Place",
        accessorKey: "show_in_best_place",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Logo",
        accessorKey: "show_logo",
        cell: ({ row, getValue }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">
                {getValue() as string}
              </Typography>
              <Typography color="text.secondary"></Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: "Actions",
        meta: {
          className: "cell-center",
        },
        disableSortBy: true,
        cell: ({ row, getValue }) => {
          const { id } = row.original;

          const collapseIcon =
            row.getCanExpand() && row.getIsExpanded() ? (
              <PlusOutlined
                style={{
                  color: theme.palette.error.main,
                  transform: "rotate(45deg)",
                }}
              />
            ) : (
              <EyeOutlined />
            );
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Tooltip title="View">
                <IconButton
                  color="secondary"
                  onClick={row.getToggleExpandedHandler()}
                >
                  {collapseIcon}
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedCustomer(row.original);
                    setCustomerModal(true);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleClose();
                    setCustomerDeleteId(row.original.id);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [theme],
  );
  const [result, setResult] = useState<VacancyOrder[]>([]);
  const [countPage, setCountPage] = useState({
    current_page: 0,

    last_page: 0,
  });
  const [currentPage, setCurrenttPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default value is 10, or you can set it to `initialPageSize` if available
  const [status, setStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [logo, setLogo] = useState(null);
  const [showinBestPlace, setshowinBestPlace] = useState(null);
  const [showInApp, setshowInApp] = useState(null);
  const dispatch = useAppDispatch();
  const fetchData = () => {
    setLoading(true);
    dispatch(getAllVacanciesOrder({ pageNumber: currentPage }))
      .then((action) => {
        const { data, meta } = action.payload;
        const fetchedUCountryData: VacancyOrder[] = data;
        const rowsWithKeys = fetchedUCountryData.map((company) => ({
          ...company,
          key: company.id,
        }));
        setResult(rowsWithKeys);
        setLoading(false);
        setCountPage(meta);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user:", error);
      });
  };
  const handleApplyFilters = () => {
    const startDate = selectedDate?.[0] ?? null;
    const endDate = selectedDate?.[selectedDate.length - 1] ?? null;

    // Dispatch the FilterVacancySub action with selected filter criteria
    dispatch(
      FilterVacancyOrder({
        created_at_start: startDate,
        created_at_end: endDate,
        status,
        display_logo: logo,
        display_in_app: showInApp,
        display_in_best_place: showinBestPlace,
      }),
    )
      .then((action) => {
        const filteredcompanyData: VacancyOrder[] = action.payload.data.map(
          (company: any) => ({
            ...company,
            isActive: company.account_status === "active",
          }),
        );
        setResult(filteredcompanyData);
      })
      .catch((error) => {
        console.error("Error filtering users:", error);
      });
  };

  const handleDateChange = (dates: any) => {
    setSelectedDate(dates);
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, dispatch]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrenttPage(page); // Update the currentPage state when the page changes
  };
  const handleToggleAccountStatus = (id: number, currentStatus: string) => {
    const updatedData = result.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          account_status: currentStatus === "active" ? "inactive" : "active",
        };
      }
      return item;
    });

    setResult(updatedData);

    // Dispatch action to toggle account status here if needed
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ReactTable
          setshowInApp={setshowInApp}
          setshowinBestPlace={setshowinBestPlace}
          setLogo={setLogo}
          logo={logo}
          handleApplyFilters={handleApplyFilters}
          showinBestPlace={showinBestPlace}
          showInApp={showInApp}
          status={status}
          handleDateChange={handleDateChange}
          fetchData={fetchData}
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={result}
          columns={columns}
          modalToggler={() => {
            setCustomerModal(true);
            setSelectedCustomer(null);
          }}
          setStatus={setStatus}
          handleToggleAccountStatus={handleToggleAccountStatus}
          countPage={countPage}
          currentPage={currentPage}
          setCurrenttPage={setCurrenttPage}
          handlePageChange={handlePageChange}
        />
      )}

      <AlertVacancyDelete
        fetchData={fetchData}
        id={customerDeleteId}
        title={customerDeleteId}
        open={open}
        handleClose={handleClose}
      />
      <VacancyModal
        fetchData={fetchData}
        id={customerDeleteId}
        open={customerModal}
        modalToggler={setCustomerModal}
        customer={selectedCustomer}
      />
    </>
  );
};

export default VacanciesOrder;
