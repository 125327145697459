import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import titleURL from "../../../../utils/url/basicInfo/title";

//get AllUser
export const getAllTitles = createAsyncThunk(
  "basicInfo/getAllTitles",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/titles?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneTitles = createAsyncThunk(
  "basicInfo/getOneTitles",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(`${titleURL.GET_ONE_TITLES}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editTitle = createAsyncThunk(
  "basicInfo/editTitle",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${titleURL.EDIT_TITLES}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addTitle = createAsyncThunk(
  "basicInfo/addTitle",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(titleURL.ADD_TITLES, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteTitle = createAsyncThunk(
  "basicInfo/deleteTitle",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(`${titleURL.DELETE_TITLES}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletTitle = createAsyncThunk(
  "basicInfo/MultiDeletTitle",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(titleURL.MULTI_DELET_TITLES, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
