import {  RouterProvider } from "react-router-dom";

// project import
import router from "routes";
import ThemeCustomization from "themes";

// import RTLLayout from 'components/RTLLayout';
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import { IntlProvider } from 'react-intl';
// auth-provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import { Provider } from "react-redux";
import store from "store";


import messages_ar from '../src/utils/locales/en.json';
import RTLLayout from "components/RTLLayout";

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //
const messages = {
  'en': messages_ar,
 

  
};

const language = navigator.language.split(/[-_]/)[0];
const App = () => (

  <ThemeCustomization>
     <RTLLayout> 
    <IntlProvider locale={language} defaultLocale="en" messages={messages[language]}>
      <ScrollTop>
        <AuthProvider>
          <>
            <Provider store={store}>
              <Notistack>
                <RouterProvider router={router} />
                <Snackbar />
              </Notistack>
            </Provider>
          </>
        </AuthProvider>
      </ScrollTop>
      </IntlProvider>
   </RTLLayout>
  </ThemeCustomization>
);

export default App;
