import { useEffect, useState ,useRef } from "react";

// material-ui

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik } from "formik";
import {
  Box,
  Button,
FormControl,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,

  MenuItem,

  Select,

  Stack,

  TextField,

} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";


// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets


// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";




import MainCard from "components/MainCard";


import { FormattedMessage } from "react-intl";
import { addStaticPage, editPage, getOnePage } from "store/actions/main/staticPage";

// constant

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormPageManagmentEdit = () => {

  const pathname = window.location.pathname;
  const [value, setValue] = useState('');
  // Split the pathname into segments using "/"
  const segments = pathname.split("/");
  const fileInputRef = useRef(null);
  // Extract the resume ID (second-to-last segment) and user ID (last segment)

  const vacancyId = segments[segments?.length - 1];
  console.log(vacancyId,"vacnacy id");


  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
const [customer, setcustomer] = useState<any>();
// @ts-ignore
const [isMilitaryServiceDisabled, setIsMilitaryServiceDisabled] =
useState(false);

console.log(customer,"customer");
  useEffect(() => {
    setLoading(false);
  }, []);
 

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file was selected
    if (file) {
      
      
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        console.log(file,"file");
        setvacancyData({ ...vacancyData, page_photo: file });
        // Clear any previous file error
      } else {
        setvacancyData({ ...vacancyData, page_photo: null });
        // Set file error message
      }
    }
  };
  const save = (e: any) => {
    console.log(e,"rich text data");
    setValue(e)
  };

  useEffect(() => {
    console.log(vacancyId,"vacancy id");
    if(vacancyId!=="0"){
      console.log("im if");
      
    dispatch(getOnePage({id:vacancyId})).then((action)=>{
console.log(action.payload,"data vacancy");
 
        const  {data}  = action.payload;
        console.log(data,"vacancy data");
        setvacancyData(data)
       
      setcustomer(data)  
    })}
  }, [dispatch]);

  const [vacancyData, setvacancyData] = useState({
    id:0,
    user_id: 0,
    title: "",
    body: "",
    short_text: "",
    page_photo:null,
    order:1,
    status:"",
    lang:""
 

  });
  console.log(vacancyData,"vcancy data");
  
  const validationSchema = Yup.object().shape({
   title:
      vacancyData?.title === ""
        && Yup.string().required("Company Description is required"),
        short_text: vacancyData?.short_text=== ""
        &&  Yup.string().required("Short Text is required"),
       
        
        
       
      
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });

  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    console.log(newValue,"new value");
    
    console.log(name,"name");
    
    // Update profileData state with the new value
    setvacancyData((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));
console.log(vacancyData,"updates");

    // Toggle military service field based on selected gender
    if (name === "gender" && value === "FEMALE") {
      setIsMilitaryServiceDisabled(true);
    } else if (name === "gender" && value === "MALE") {
      setIsMilitaryServiceDisabled(false);
    }
  };
  



  
 

  
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
  <Formik
                initialValues={{
                 
                  title: vacancyData?.title,
                  short_text:vacancyData?.short_text||"",
                  body: vacancyData?.body||"",
                 
                 
                  page_photo: vacancyData?.page_photo

                }}
                validationSchema={validationSchema} // Pass the validation schema to Formik
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values,"values");
                  
                  // Check if account_photo is an image
                 
                    // Prepare FormData object

                    const formData = new FormData();
                      console.log(vacancyData,"vacancy data");
                                         
                    formData.append("title", vacancyData?.title);
                    // @ts-ignore
                    formData.append("user_id", 3);
                    console.log(formData,"form data");
                    
                    formData.append(
                      "short_text",
                      vacancyData?.short_text,
                    );
                    formData.append(
                      "body",
                      value,
                    );
                    formData.append("page_photo", vacancyData?.page_photo);
                    formData.append("lang", vacancyData?.lang);
                    formData.append("status", vacancyData?.status);
                    

                   // Assuming code is an object with only one key-value pair

                    // Concatenate country code and phone number

                  
                   
                    if (!customer) {
                      console.log(formData,"values form data");
                      
                      dispatch(addStaticPage(formData)).then(
                        (action) => {
                          if (
                            action.type ===
                            "jobSeeker/addNewJobSeekerProfile/fulfilled"
                          ) {
                            openSnackbar({
                              open: true,
                              message: "profile added successfully",
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                              },
                              variant: "alert",
                              alert: {
                                color: "success",
                              },
                            } as SnackbarProps);
                            setSubmitting(false);
                          }
                        },
                      );
                    } else {
                      dispatch(
                        editPage({
                          id: vacancyId,
                          data: formData,
                        }),
                      ).then((action) => {
                        if (
                          action.type ===
                          "jobSeeker/editJobSeekerProfile/fulfilled"
                        ) {
                          openSnackbar({
                            open: true,
                            message: "profile Updated successfully",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "alert",
                            alert: {
                              color: "success",
                            },
                          } as SnackbarProps);
                          setSubmitting(false);
                        }
                      });
                    }

                    // Send FormData with Axios
                    // Set file error message
                   // Your form submission logic here
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values
                }) => (
                  <form className="form-vacancy-container" noValidate onSubmit={handleSubmit}>
      
        <LocalizationProvider>
          
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px',}} >
              {customer ? "Edit Page" : "New Page"}
             
            </DialogTitle>
         
   
            <MainCard >
            <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="company_description">
                         Title
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.title}
                          name="title" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 10 }}
                         
                          placeholder="Title"
                          error={touched?.title && Boolean(errors?.title)} 
                          helperText={touched?.title && errors?.title}
                          
                        />
                        
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                          Short Text
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.short_text}
                          name="short_text" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                         
                          placeholder="Short Text"
                          error={touched?.short_text && Boolean(errors?.short_text)} 
                          helperText={touched?.short_text && errors?.short_text}
                          
                        />
                        </Stack></Grid>
                
                      
            
                    
                        
                   
                     
                    <Grid style={{height:"250px"}} item xs={12} sm={12}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="body">
                    Body
                        </InputLabel>
                          <ReactQuill  style={{height:"150px"}} theme="snow" value={vacancyData.body} onChange={save} />
                       
                        {/* <TextArea
                         rows={4}
                          
                          value={vacancyData?.body}
                          name="body" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          
                        
                          placeholder="body"
                          
                        /> */}
                        {/* {touched.body&&
                                        errors.body && (
                                          <FormHelperText error>
                                            {errors.body}
                                          </FormHelperText>
                                        )} */}
                        </Stack></Grid>
                        

<Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="lang">
                                Language
                                </InputLabel>
                                
                            
                            
                                  <FormControl fullWidth>
                                    <Select
                                      
                                      value={vacancyData?.lang}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                      
                                      name="lang"
                                    >
                                      <MenuItem value="EN">
                                        English
                                      </MenuItem>
                                      <MenuItem value="AR">
                                        Arabic
                                      </MenuItem>
                                      
                                    </Select>
                                  </FormControl>
                              
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="lang">
                                Status
                                </InputLabel>
                                
                            
                            
                                  <FormControl fullWidth>
                                    <Select
                                      
                                      value={vacancyData?.status}
                                      onChange={(e:any)=>handleChange(e)}
                                      onBlur={handleBlur}
                                      
                                      name="status"
                                    >
                                      <MenuItem value="published">
                                      Published
                                      </MenuItem>
                                      <MenuItem value="unpublished">
                                      Unpublished
                                      </MenuItem>
                                      
                                    </Select>
                                  </FormControl>
                              
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                        <Stack direction={"row"}>
                                    <input
                                      type="file"
                                      accept="image/*" // Allow only image files
                                      ref={fileInputRef} // Attach the reference to the file input
                                      // style={{ display: "none" }} // Hide the input element
                                      onChange={handleFileChange}
                                      // Handle file change event
                                    />
                                    <Button
                                      onClick={() =>
                                        fileInputRef.current.click()
                                      } // Trigger file input click on button click
                                    >
                                      Upload Photo
                                    </Button>
                                  </Stack>
</Grid>

                        </Grid></Grid>
            </MainCard>
     
           

            <MainCard>
            <Button
                            
                            type="submit"
                            variant="contained"
                          >
                            Save
                          </Button> 
            </MainCard>
            <Divider />
       
        
     
        </LocalizationProvider>
    </form>
                )}
      </Formik>
    </>
  );
};

export default FormPageManagmentEdit;
