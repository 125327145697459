import { useMemo } from "react";

// material-ui
import { Box, Modal, Stack } from "@mui/material";

// project-imports
import FormCustomerAdd from "./FormVacancyAdd";
import MainCard from "components/MainCard";
import SimpleBar from "components/third-party/SimpleBar";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

// types

import FormCountryAdd from "./FormVacancyAdd";
import { CountryList, UserList } from "types/customer";
import FormUserAdd from "./FormVacancyAdd";
import FormCompanyAdd from "./FormVacancyAdd";
import { PackagesVacancies } from "model/packages";
import FormVacancyAdd from "./FormVacancyAdd";

interface Props {
  open: boolean;
  modalToggler: (state: boolean) => void;
  customer?: PackagesVacancies | null;
  id: number;
  fetchData: any;
}

// ==============================|| CUSTOMER ADD / EDIT ||============================== //

const VacancyModal = ({
  fetchData,
  open,
  modalToggler,
  customer,
  id,
}: Props) => {
  const closeModal = () => modalToggler(false);

  const customerForm = useMemo(
    () => (
      <FormVacancyAdd
        fetchData={fetchData}
        id={id}
        customer={customer || null}
        closeModal={closeModal}
      />
    ),
    // eslint-disable-next-line
    [customer],
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-customer-add-label"
          aria-describedby="modal-customer-add-description"
          sx={{
            "& .MuiPaper-root:focus": {
              outline: "none",
            },
          }}
        >
          <MainCard
            sx={{
              width: `calc(100% - 48px)`,
              minWidth: 340,
              maxWidth: 600,
              height: "auto",
              maxHeight: "calc(100vh - 48px)",
            }}
            modal
            content={false}
          >
            <SimpleBar
              sx={{
                maxHeight: `calc(100vh - 48px)`,
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {customerForm}
            </SimpleBar>
          </MainCard>
        </Modal>
      )}
    </>
  );
};

export default VacancyModal;
