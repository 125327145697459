import { useState,useEffect} from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,

  Stack,
  
  TextField,
  Tooltip,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";

import { useFormik, Form, FormikProvider } from "formik";

// project imports
import IconButton from "components/@extended/IconButton";


import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";
import Checkbox from "@mui/material/Checkbox";
import AlertCountryDelete from "./AlertRolesDelete";


import { RolesModel } from "model/roles";
import { addNewRoles, editRole, getPermission } from "store/actions/main/roles";
import MainCard from "components/MainCard";

// constant
const getInitialValues = (customer: RolesModel | null) => {
  const newCustomer = {
    name: "",
    permissions: [],
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormRolesManagmentEdit = ({
  customer,
  id,
  fetchData,
 
  loading,
  closeModal,
  setLoading,
}: {
  fetchData?: any;
 
  loading?: any;
  id?: number;
  setLoading?: any;
  customer?: RolesModel | null;
  closeModal?: () => void;
}) => {
    const [permissions, setPermissions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const pathname = window.location.pathname;
  const segments = pathname.split("/");
  const roleId = segments[segments?.length - 1];


  const [openAlert, setOpenAlert] = useState(false);


useEffect(() => {
    dispatch(getPermission())
      .then((action) => {
        const data = action.payload;

        const fetchedUserData: any[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setPermissions(rowsWithKeys);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching users:", error);
      });
}, [dispatch]);
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    //  validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        

        if (roleId!==0) {
          // Dispatch editCategories action if customer exists
          await dispatch(editRole({ id: customer.id || 0, data: values })).then(
            (action) => {
              fetchData();
              if (action.type === "role/editRole/fulfilled") {
                openSnackbar({
                  open: true,
                  message: "Roles edited successfully",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                  variant: "alert",
                  alert: {
                    color: "success",
                  },
                } as SnackbarProps);
                setSubmitting(false);
                closeModal();
              }
            },
          );
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addNewRoles(values)).then((action) => {
            fetchData();
            if (action.type === "role/addNewRoles/fulfilled") {
              openSnackbar({
                open: true,
                message: "Roles edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }

        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;



  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{customer ? "Edit Role" : "New Role"}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
             
                
            
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-name">
                          Role Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Stack className="main-resume-container permission-card" direction={"column"}>
                    <MainCard style={{marginTop:"10px"}}  content={false}
              title="Personal Information"
              sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }} >

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                     
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <FormGroup row>
                            {permissions.map((permission: any) => (
                              <FormControlLabel
                                key={permission.key} // Assuming 'key' is a unique identifier for each permission
                                control={
                                  <Checkbox
                                    {...getFieldProps(
                                      `permissions.${permission.name}`,
                                    )}
                                    checked={formik.values.permissions.includes(
                                      permission.name,
                                    )}
                                    onChange={(event) => {
                                      const isChecked = event.target.checked;
                                      formik.setFieldValue(
                                        "permissions",
                                        isChecked
                                          ? [
                                              ...formik.values.permissions,
                                              permission.name,
                                            ]
                                          : formik.values.permissions.filter(
                                              (perm) =>
                                                perm !== permission.name,
                                            ),
                                      );
                                    }}
                                  />
                                }
                                label={permission.name} // Assuming 'name' is the permission name
                              />
                            ))}
                          </FormGroup>
                        </Box>
                      </Stack>
                    </Grid>
                    </MainCard>
                    </Stack>
                 
            
          
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    
    </>
  );
};

export default FormRolesManagmentEdit;
