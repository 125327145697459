import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { login, logout } from "../../actions/main/auth";
import { SignResponseModel } from "model/sign";

const initialState: SignResponseModel = {
  isOk: false,
  result: {
    email: "",
    password: "",
  },
  token: "",
  account_type: "",
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.rejected.type, (state, action: PayloadAction<string>) => {
        state.isOk = false;
        state.token = "some thing went wrong";
      })
      .addCase(
        login.fulfilled.type,
        (state, action: PayloadAction<SignResponseModel>) => {
          sessionStorage.setItem("sys-token", action.payload.token);
          state.isOk = action.payload.isOk;
          state.token = action.payload.token;
          state.result = action.payload.result;
        },
      )
      .addCase(logout.rejected.type, (state, action: PayloadAction<string>) => {
        state.isOk = false;
      })
      .addCase(
        logout.fulfilled.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = true;
          sessionStorage.removeItem("sys-token");
        },
      );
  },
});

export const { resetStateStatus } = authenticationSlice.actions;

export default authenticationSlice;
