import { CardHeader, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableExperience } from "./TableExperience";



import ExperienceDialog from "./Experience";



const ExperienceSection = ({
  openExp,
  setOpenExp,
  cvId,
  basicInfo,
  setLoading,
  loading,
  fetchGetOneExp,
}: {
  fetchGetOneExp: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openExp: any;
  setOpenExp: (openExp: boolean) => void;
  cvId: any;
}) => {
 console.log(cvId,"cv id");
 console.log(basicInfo,"basic ingo")

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          {basicInfo?.resume_experiences?.length==0?<Button
      
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenExp(true)}
        >
          Add Work Experience
        </Button>:<Button
        className="add-button-resume-section"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenExp(true)}
        >
          Add Work Experience
        </Button>}
        
          <CardHeader className="tabs-card-resumes" title="Experiences" />
          
          <Stack  style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableExperience
              cvId={cvId}
              educationCertificates={basicInfo}
              setLoading={setLoading}
              loading={loading}
              fetchGetOneExp={fetchGetOneExp}
            />
          </Stack>
        </Stack>
      </Stack>
      
        {openExp && (
          <ExperienceDialog
            setLoading={setLoading}
            loading={loading}
            openExp={openExp}
            setOpenExp={setOpenExp}
            cvId={cvId}
            fetchGetOneExp={fetchGetOneExp}
          />
        )}
   
    </>
  );
};

export default ExperienceSection;
