// material-ui
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";

// types
interface StatusProps {
  value: number;
  label: string;
}

// ==============================|| EXPANDING TABLE - USER DETAILS ||============================== //

const ExpandingVacancyDetail = ({ data }: any) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      spacing={3.5}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Grid item xs={12} sm={7} md={12} xl={8.5}>
        <Stack spacing={2.5}>
          <MainCard title="Vacancy Orders Details">
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Company Name</Typography>
                      <Typography>{data.company_name}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Count Vacancies</Typography>
                      <Typography>{data.amount_vacancies}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Price</Typography>
                      <Typography>{data.price}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Status</Typography>
                      <Typography>
                        {data.status === true ? "active" : "inactive"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Validity</Typography>
                      <Typography>{data.package_validity}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Duration</Typography>
                      <Typography>{data.package_duration}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Payment</Typography>
                      <Typography>{data.payment_id}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Show Logo</Typography>
                      <Typography>
                        {data.show_logo === true ? "Yes" : "No"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">
                        Show In Best place
                      </Typography>
                      <Typography>
                        {data.show_in_best_place === true ? "Yes" : "No"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Show In App</Typography>
                      <Typography>
                        {data.show_in_app === true ? "Yes" : "No"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Created At</Typography>
                      <Typography>{data.created_at}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Updated At</Typography>
                      <Typography>{data.updated_at}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </MainCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ExpandingVacancyDetail;
