import { useEffect, useMemo, useState } from "react";

// material-ui
import { Box, Modal, Stack } from "@mui/material";

// project-imports
import FormCustomerAdd from "./FormRolesAdd";
import MainCard from "components/MainCard";
import SimpleBar from "components/third-party/SimpleBar";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

// types

import FormUserAdd from "./FormRolesAdd";
import { RolesModel } from "model/roles";
import FormRolesAdd from "./FormRolesAdd";
import { useAppDispatch } from "hooks";
import { getPermission } from "store/actions/main/roles";

interface Props {
  open: boolean;
  modalToggler: (state: boolean) => void;
  customer?: RolesModel | null;
  id: number;
  fetchData: any;
}

// ==============================|| CUSTOMER ADD / EDIT ||============================== //

const RolesModal = ({ open, modalToggler, customer, id, fetchData }: Props) => {
  const closeModal = () => modalToggler(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [permissions, setPermissions] = useState<any>([]);
  const fetchRolesData = (url?: string) => {
    setLoading(true);
    dispatch(getPermission())
      .then((action) => {
        const data = action.payload;

        const fetchedUserData: any[] = data;
        const rowsWithKeys = fetchedUserData.map((user) => ({
          ...user,
          key: user.id,
        }));
        setPermissions(rowsWithKeys);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    // Fetch permissions data here
    fetchRolesData();
  }, [open, dispatch, id]);
  const customerForm = useMemo(
    () =>
      !loading && (
        <FormRolesAdd
          id={id}
          loading={loading}
          permissions={permissions}
          setLoading={setLoading}
          fetchData={fetchData}
          customer={customer || null}
          closeModal={closeModal}
        />
      ),
    // eslint-disable-next-line
    [customer, loading],
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-customer-add-label"
          aria-describedby="modal-customer-add-description"
          sx={{
            "& .MuiPaper-root:focus": {
              outline: "none",
            },
          }}
        >
          <MainCard
            sx={{
              width: `calc(100% - 48px)`,
              minWidth: 340,
              maxWidth: 880,
              height: "auto",
              maxHeight: "calc(100vh - 48px)",
            }}
            modal
            content={false}
          >
            <SimpleBar
              sx={{
                maxHeight: `calc(100vh - 48px)`,
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {loading ? (
                <Box sx={{ p: 5 }}>
                  <Stack direction="row" justifyContent="center">
                    <CircularWithPath />
                  </Stack>
                </Box>
              ) : (
                customerForm
              )}
            </SimpleBar>
          </MainCard>
        </Modal>
      )}
    </>
  );
};

export default RolesModal;
